import { Menu as MantineMenu } from '@mantine/core';
import {
  IconAppsFilled,
  IconHome2,
  IconLogout,
  IconMenu,
  IconUserCircle,
} from '@tabler/icons-react';
import { signOut } from '../../modules/auth';
import { useAppDispatch } from '../../hooks';

import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { ProfileDto } from '../../modules/user';
import { AppAvatar } from '../app-avatar/AppAvatar';
import { AppCreatorLabel } from '../app-creator-label/AppCreatorLabel';
import styles from './Menu.module.scss';
import { isCreator, resizeImage } from '../../utils';

type Props = {
  profile: ProfileDto | null;
  className?: string;
};

export function Menu({ profile, className }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function logout() {
    dispatch(signOut());
  }

  return (
    <MantineMenu
      transitionProps={{ transition: 'scale-y', duration: 150 }}
      position='bottom-end'
      shadow='md'
      arrowPosition='center'
      width={180}
    >
      <MantineMenu.Target>
        <div className={styles.target}>
          {profile ? (
            <AppAvatar
              className={styles.avatar}
              address={profile.address}
              avatar={profile.avatar && resizeImage(profile.avatar, 64, 64)}
            />
          ) : (
            <IconMenu className={styles.burger} />
          )}
        </div>
      </MantineMenu.Target>

      <MantineMenu.Dropdown>
        {profile && (
          <>
            <AppCreatorLabel
              className={styles.label}
              id={profile.id}
              username={profile.username}
              address={profile.address}
              avatar={profile.avatar}
              isSmall
              // isIconDisplayed={false}
            />
            <MantineMenu.Divider />
          </>
        )}
        <MantineMenu.Item onClick={() => navigate(AppRoute.Home)} icon={<IconHome2 size={16} />}>
          Home
        </MantineMenu.Item>

        {isCreator(profile) && (
          <MantineMenu.Item
            onClick={() => navigate(AppRoute.CreateCollection)}
            icon={<IconAppsFilled size={16} />}
          >
            Create collection
          </MantineMenu.Item>
        )}

        {profile && (
          <>
            <MantineMenu.Item
              onClick={() => navigate(`${AppRoute.User}/${profile.id}`)}
              icon={<IconUserCircle size={16} />}
            >
              Profile
            </MantineMenu.Item>
            <MantineMenu.Item onClick={logout} icon={<IconLogout size={16} />}>
              Log out
            </MantineMenu.Item>
          </>
        )}
      </MantineMenu.Dropdown>
    </MantineMenu>
  );
}

import { createAsyncThunk, isRejected } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { provider } from './ethersProvider';
import {
  factoryContract,
  getFanCollectionContract,
  getFundingCollectionContract,
} from './ethersContracts';
import {
  INSUFFICIENT_FUNDS_ERROR_CODE,
  networkMap,
  REJECTED_ERROR_CODE,
  SUPPORTED_NETWORK,
  UNKNOWN_NETWORK,
} from './ethersConstants';
import { areAddressesSame, ethToWei, getBaseURI } from '../utils';
import {
  CreateCollectionForm,
  CreateFundingCollectionForm,
  UpdateOptionDto,
} from '../modules/collection';
import { mapOptions } from './ethersServices';
import { RootState } from '../store';
import { ENVIRONMENT } from '../constants';
import { Environment } from '../types';

export const deployFanCollection = createAsyncThunk<void, CreateCollectionForm>(
  'ethers/deployFanCollection',
  async ({ priceWeb3, symbol, maxNft, name }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const factoryContractWithSigner = factoryContract.connect(signer);

    await factoryContractWithSigner.createFanCollection(
      await signer.getAddress(),
      name,
      symbol.toUpperCase(),
      getBaseURI(symbol),
      getBaseURI(symbol, true),
      maxNft,
      ethToWei(priceWeb3),
      true,
    );
  },
);

export const deployFundingCollection = createAsyncThunk<void, CreateFundingCollectionForm>(
  'ethers/deployFundingCollection',
  async ({ symbol, name }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const factoryContractWithSigner = factoryContract.connect(signer);

    await factoryContractWithSigner.createFundingCollection(
      await signer.getAddress(),
      name,
      symbol.toUpperCase(),
      getBaseURI(symbol),
      getBaseURI(symbol, true),
    );
  },
);

export const mintToken = createAsyncThunk<
  void,
  { to: string; quantity: number; collectionAddress: string; priceWeb3: number },
  { state: RootState }
>(
  'ethers/mintToken',
  async ({ to, quantity, collectionAddress, priceWeb3 }, { rejectWithValue, getState }) => {
    const signer = provider?.getSigner();
    const user = getState().auth.user;
    const userAndSignerMatch = areAddressesSame(await signer?.getAddress(), user?.address);

    if (!signer || !userAndSignerMatch) {
      return rejectWithValue("Can't get signer");
    }

    const collectionContractWithSigner =
      getFanCollectionContract(collectionAddress).connect(signer);

    try {
      await collectionContractWithSigner.publicMint(to, quantity, {
        value: ethToWei(priceWeb3),
      });
    } catch (e: any) {
      if (e.code === INSUFFICIENT_FUNDS_ERROR_CODE || e.code === REJECTED_ERROR_CODE) {
        return rejectWithValue(e.code);
      } else {
        throw e;
      }
    }
  },
);

export const mintFundingToken = createAsyncThunk<
  void,
  {
    to: string;
    quantity: number;
    collectionAddress: string;
    priceWeb3: number;
    externalIndex: number;
  },
  { state: RootState }
>(
  'ethers/mintFundingToken',
  async (
    { to, quantity, collectionAddress, priceWeb3, externalIndex },
    { rejectWithValue, getState },
  ) => {
    const signer = provider?.getSigner();
    const user = getState().auth.user;
    const userAndSignerMatch = areAddressesSame(await signer?.getAddress(), user?.address);

    if (!signer || !userAndSignerMatch) {
      return rejectWithValue("Can't get signer");
    }

    const collectionContractWithSigner =
      getFundingCollectionContract(collectionAddress).connect(signer);

    try {
      await collectionContractWithSigner.publicMint(to, quantity, externalIndex, {
        value: ethToWei(priceWeb3),
      });
    } catch (e: any) {
      if (e.code === INSUFFICIENT_FUNDS_ERROR_CODE || e.code === REJECTED_ERROR_CODE) {
        return rejectWithValue(e.code);
      } else {
        throw e;
      }
    }
  },
);

export const updateOptions = createAsyncThunk<
  void,
  { collectionAddress: string; options: UpdateOptionDto[] }
>('ethers/updateOptions', async ({ collectionAddress, options }, { rejectWithValue }) => {
  const signer = provider?.getSigner();

  if (!signer) {
    return rejectWithValue("Can't get signer");
  }

  const collectionContractWithSigner =
    getFundingCollectionContract(collectionAddress).connect(signer);
  const { optionPrices, optionSupply } = mapOptions(options);
  await collectionContractWithSigner.updateOptions(optionSupply, optionPrices);
});

export const setOptions = createAsyncThunk<
  void,
  { collectionAddress: string; options: UpdateOptionDto[] }
>('ethers/setOptions', async ({ collectionAddress, options }, { rejectWithValue }) => {
  const signer = provider?.getSigner();

  if (!signer) {
    return rejectWithValue("Can't get signer");
  }

  const collectionContractWithSigner =
    getFundingCollectionContract(collectionAddress).connect(signer);
  const { optionPrices, optionSupply } = mapOptions(options);
  await collectionContractWithSigner.setOptions(optionPrices, optionSupply);
});

export const safeSwitchNetwork = createAsyncThunk<void, void>(
  'ethers/switchNetwork',
  async (arg, { rejectWithValue, dispatch }) => {
    const result = await dispatch(switchNetwork());
    if (isRejected(result) && result.payload === UNKNOWN_NETWORK) {
      await dispatch(addMaticNetwork());
      await dispatch(switchNetwork());
    }
  },
);

export const switchNetwork = createAsyncThunk<void, void>(
  'ethers/switchNetwork',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexValue(SUPPORTED_NETWORK.id) }],
      });
    } catch (e: any) {
      if (e.code === UNKNOWN_NETWORK) {
        return rejectWithValue(e.code);
      } else {
        throw e;
      }
    }
  },
);
export const addMaticNetwork = createAsyncThunk<void, void>(
  'ethers/addMaticNetwork',
  async (arg, { rejectWithValue }) => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        networkMap[ENVIRONMENT === Environment.Production ? 'POLYGON_MAINNET' : 'MUMBAI_TESTNET'],
      ],
    });
  },
);

import { LocalStorage } from "../enums";

export const prepareHeaders = (headers: Headers) => {
  const token = localStorage.getItem(LocalStorage.AccessToken);

  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
};

import { z } from 'zod';
import { signInSchema, signUpSchema } from './authSchema';

export type SignInForm = z.infer<typeof signInSchema>;
export type SignUpForm = z.infer<typeof signUpSchema>;

export enum Role {
  User = 'USER',
  Creator = 'CREATOR',
  Admin = 'ADMIN',
}

export enum WalletType {
  METAMASK = 'METAMASK',
  INTERNAL = 'INTERNAL',
}

export interface IAccount {
  address: string;
}

export interface IUser {
  id: string;
  address: string;
  type: WalletType;
  roles: Role[];
}

export interface NonceDto {
  nonce: number;
}

export interface TokensDto {
  accessToken: string;
  refreshToken: string;
}

export interface SignInDto {
  address: string;
  signature: string;
}

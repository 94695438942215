import { UseFormReturnType } from '@mantine/form/lib/types';
import { FileObject } from '../components/app-file-uploader/types';
import { FileDto } from '../modules/file';
import { z } from 'zod';

export const isNewOption = (optionId: string): boolean => {
  return optionId.length !== 24;
};

export const getImageField = (size?: number) => {
  return typeof window === 'undefined' ? z.any() : z.string();
};

export const getVideoField = (size: number) => {
  return typeof window === 'undefined' ? z.any() : z.string();
};

export const validateAsync = async <T>(
  schema: z.ZodType<T>,
  values: T,
  form: UseFormReturnType<any extends T ? T : any>,
) => {
  const result = await schema.safeParseAsync(values);

  if (!result.success) {
    form.setErrors({ ...result.error.formErrors.fieldErrors });
  }

  return result;
};

export const mapFileObj = (obj: FileObject): FileDto => {
  const { id, size, name, isPublic, type, file, mimeType } = obj;

  return {
    id,
    name,
    size,
    mimeType: file?.type || mimeType,
    type,
    isPublic,
    description: null,
  };
};

export function formatCurrencyInput(value: string) {
  if (!Number.isNaN(parseFloat(value))) {
    const formattedValue = parseFloat(value)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$ ${formattedValue}`;
  } else {
    return '$ ';
  }
}

export function parseCurrencyInput(value: string) {
  return parseFloat(value.replace(/\$\s?|(,*)/g, '')).toFixed(0);
}

import { z } from 'zod';
import {
  fanCollectionSchema,
  addDescriptionSchema,
  importImdbSchema,
  bonusSchema,
  uploadVideosSchema,
  uploadTrailersSchema,
  fundingCollectionSchema,
  storySchema,
  goalSchema,
  videosAndPhotosSchema,
  pitchdeckSchema,
  optionSchema,
  dropConfigSchema,
} from './collectionSchema';
import { Role } from '../auth';
import { FileDto } from '../file';
import { VideoDto } from '../../types';
import { CollectorDto, CreatorPreviewDto } from '../user';

export type CreateCollectionForm = z.infer<typeof fanCollectionSchema>;
export type CreateFundingCollectionForm = z.infer<typeof fundingCollectionSchema>;
export type ImportImdbForm = z.infer<typeof importImdbSchema>;
export type UploadVideosForm = z.infer<typeof uploadVideosSchema>;
export type UploadTrailersForm = z.infer<typeof uploadTrailersSchema>;
export type VideosAndPhotosForm = z.infer<typeof videosAndPhotosSchema>;
export type PitchdeckForm = z.infer<typeof pitchdeckSchema>;
export type StoryForm = z.infer<typeof storySchema>;
export type GoalForm = z.infer<typeof goalSchema>;
export type DropConfigForm = z.infer<typeof dropConfigSchema>;
export type AddDescriptionForm = z.infer<typeof addDescriptionSchema>;
export type AddBonusForm = z.infer<typeof bonusSchema>;
export type OptionForm = z.infer<typeof optionSchema>;

export enum CollectionType {
  Fan = 'fan',
  Funding = 'funding',
}

export enum StageName {
  PRE_PRODUCTION = 'PRE_PRODUCTION',
  PRODUCTION = 'PRODUCTION',
  POST_PRODUCTION = 'POST_PRODUCTION',
}

export type Subtask = {
  id: string;
  title: string;
  description: string;
  isDone: boolean;
};

export type Task = { id: string; title: string; date: string; subtasks: Subtask[] };

export enum IconBackground {
  Lime = 'LIME',
  LimeMint = 'LIME_MINT',
  Pink = 'PINK',
  Red = 'RED',
  Orange = 'ORANGE',
  Blue = 'BLUE',
  LightBlue = 'LIGHT_BLUE',
  Mint = 'MINT',
}

export enum Icon {
  Bag = 'BAG',
  Calendar = 'CALENDAR',
  Camera = 'CAMERA',
  Category = 'CATEGORY',
  Community = 'COMMUNITY',
  Discord = 'DISCORD',
  Discount = 'DISCOUNT',
  Download = 'DOWNLOAD',
  Edit = 'EDIT',
  Envelop = 'ENVELOP',
  Facebook = 'FACEBOOK',
  Home = 'HOME',
  Image = 'IMAGE',
  Microphone = 'MICROPHONE',
  Play = 'PLAY',
  Show = 'SHOW',
  Speaker = 'SPEAKER',
  Ticket = 'TICKET',
  Video = 'VIDEO',
  Youtube = 'YOUTUBE',
}

export enum ContentType {
  Watch = 'WATCH',
  Download = 'DOWNLOAD',
  File = 'FILE',
  Link = 'LINK',
  Text = 'TEXT',
  None = 'NONE',
}

export interface Image {
  id: string;
  url: string;
}

export interface Film {
  id: string;
  title: string;
  images: Image[];
  poster: string;
  imdbId: string;
  background: string;
  countries: string;
  year: string;
  plot: string;
  keywordList: string[];
  languages: string;
  genres: string;
  runtimeStr: string;
  releaseDate: string | null;
  directors: string;
  writers: string;
  budget: string;
  stars: string;
  collectionId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CollectionPreview {
  id: string;
  symbol: string;
  name: string;
  priceWeb3: number;
  price: number;
  maxNft: number;
  nftCount: number;
  bonusesCount: number;
  isPublished: boolean;
  type: CollectionType;
  nftImage: string | null;
  background: string | null;
  user: CreatorPreviewDto;
}

export interface FilmShelfPreviewDto {
  collectionId: string;
  collectionSymbol: string;
  poster: string;
}

export interface Collection {
  id: string;
  address: string;
  name: string;
  symbol: string;
  nftCount: number;
  maxNft: number;
  price: number;
  priceWeb3: number;
  nftImage: string;
  description: string | null;
  hasAccess: boolean;
  film: Film;
  bonuses: BonusDto[];
  audience: CollectorDto[];
  trailers: VideoDto[];
  video: VideoDto | null;
  user: {
    id: string;
    email: string | null;
    fullName: string | null;
    username: string | null;
    address: string;
    avatar: string | null;
    roles: Role[];
  };
  createdAt: string;
}

export interface OptionPreviewDto {
  id: string;
  nftImage: string;
  price: number;
  priceWeb3: number;
  name: string;
  description: string;
  maxNft: number;
  tokenCount: number;
  userTokenCount: number;
  externalIndex: number;
  type: ContentType;
  stageName: StageName;
}

export interface FundingCollectionDto {
  id: string;
  address: string;
  name: string;
  symbol: string;
  coverImage: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  isPublished: boolean;
  areOptionsSigned: boolean;
  story: string;
  goal: number;
  scriptId: string;
  ownedBy: number;
  collectors: CollectorDto[];
  options: OptionPreviewDto[];
  pitchdeckId: string;
  pitchVideo: VideoDto;
  videos: VideoDto[];
  images: string[];
  isWaitlist: boolean;
  waitlistCount: number;
  waitlistSize: number;
  hasDiscount: boolean;
  waitlistDiscount: number;
  user: CreatorPreviewDto;
  activeStage: Stage | null;
  stages: Stage[];
}

export interface CollectionForm {
  id: string;
  address: string | null;
  name: string;
  symbol: string;
  maxNft: number;
  priceWeb3: number;
  nftImage: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  description: string | null;
  movieId: string | null;
  trailers: FileDto[];
  isPublished: boolean;
  film: Film | null;
  bonuses: BonusDto[];
}

export interface FundingCollectionForm {
  id: string;
  address: string | null;
  name: string;
  symbol: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  isPublished: boolean | null;
  areOptionsSigned: boolean;
  story: string | null;
  goal: number | null;
  waitlistSize: number | null;
  isWaitlist: boolean;
  waitlistDiscount: number | null;
  pitchVideo: FileDto | null;
  pitchdeck: FileDto | null;
  script: FileDto | null;
  coverImage: FileDto | null;
  videos: FileDto[];
  images: FileDto[];
  options: OptionDto[];
  activeStage: Stage | null;
  stages: Stage[];
}

export interface BonusDto {
  id: string;
  name: string;
  description: string;
  icon: Icon;
  background: IconBackground;
  type: ContentType;
  content: string[];
  files: FileDto[];
  isDefault?: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
}

export interface Stage {
  id: string;
  waitlistUserIds: string[];
  notifiedWaitlistUserIds: string[];
  waitlistSize: number | null;
  goal: number | null;
  percentage: number | null;
  couponId: string | null;
  collectionId: string;
  canGoNext: boolean;
  name: StageName;
  tasks: Task[];
}

export interface OptionDto {
  id: string;
  name: string;
  nftImage: FileDto;
  description: string;
  type: ContentType;
  content: string[];
  files: FileDto[];
  maxNft: number;
  price: number;
  priceWeb3: number;
  externalIndex: number;
  isDisabled: boolean;
  stageName: StageName;
  _count: { tokens: number };
}

export type UpdateOptionDto = Omit<OptionDto, 'id' | 'externalIndex'> & { id?: string };

export type BonusContentDto = {
  content: string;
  name?: string;
  size?: number;
};

import Identicon from 'identicon.js';

export * from './api.util';
export * from './ethers.util';
export * from './object.util';
export * from './auth.util';
export * from './imdb.utils';
export * from './form.util';

export function resizeImage(url: string, width: number, height?: number): string {
  return `${url}${getImageUrlParams(width, height)}`;
}

export function getImageUrlParams(width: number, height?: number): string {
  return `?width=${width}&height=${height || ''}`;
}

export function getCompactNumber(value: number): string {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(value);
}

export function getTestAsyncFunction(timeout: number = 1000): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

export function getIdenticonSource(hash: string): string {
  const base64source = new Identicon(hash, {
    margin: 0.2,
    background: [0, 0, 0, 0],
    size: 420,
    format: 'svg',
  }).toString();

  return `data:image/svg+xml;base64,${base64source}`;
}

export function getPercentage(portion: number, whole: number): number {
  return (portion / whole) * 100;
}

export function getUrlExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
}

export function formatCurrency(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(value);
}

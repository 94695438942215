import { Role } from '../modules/auth';
import { Link } from './link';
import { AppRoute } from './appRoute';

export { AppRoute };

export const links: Link[] = [
  {
    route: AppRoute.Home,
    label: 'Home',
  },
  // {
  //   route: AppRoute.Marketplace,
  //   label: "Marketplace",
  // },
  // {
  //   route: AppRoute.MyFilms,
  //   label: "My Films",
  //   roles: [Role.Creator],
  // },
  // {
  //   route: AppRoute.Purchases,
  //   label: "My Purchases",
  // },
  // {
  //   route: AppRoute.GettingStarted,
  //   label: "Getting Started"
  // },
  {
    route: AppRoute.CreateCollection,
    label: 'Create collection',
    roles: [Role.Creator],
  },
];

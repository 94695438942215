import { AppDispatch, RootState } from '../store';
import { onAccountChange } from '../modules/auth';
import { Network, Web3Provider } from '@ethersproject/providers';
import { networkChanged } from '../modules/config';
import { ethereum } from './ethersProvider';

export function listenWeb3Events(dispatch: AppDispatch, provider: Web3Provider) {
  listenProvider(dispatch, provider);
}

const listenProvider = (dispatch: AppDispatch, provider: Web3Provider) => {
  provider.on('network', ({ ensAddress, chainId, name }: Network, oldNetwork: Network) => {
    dispatch(networkChanged({ ensAddress, name, chainId }));
  });
  ethereum.on('accountsChanged', (accounts: string[]) => {
    dispatch(onAccountChange({ accounts }));
  });
};

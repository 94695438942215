import { Network } from '@ethersproject/networks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServiceAvailability } from './configServices';
import { ServiceAvailability } from './configConstants';
import { provider } from '../../ethers';
import { setAccount, setUser, WalletType } from '../auth';
import { areAddressesSame, getUserFromToken } from '../../utils';
import { findProfile } from '../user';
import { utils } from 'ethers';

export const init = createAsyncThunk<void, void>('config/init', async (arg, { dispatch }) => {
  const user = getUserFromToken();
  if (!user) return;

  if (user.type === WalletType.INTERNAL) {
    dispatch(setUser(user));
    dispatch(findProfile());
  } else {
    if (!provider) return;
    const { chainId, name, ensAddress } = await provider!.detectNetwork();
    await dispatch(networkChanged({ chainId, name, ensAddress }));

    const [address] = await provider.listAccounts();
    const addressMatch = areAddressesSame(address, user.address);

    if (addressMatch) {
      dispatch(setUser(user));
      dispatch(findProfile());
      dispatch(setAccount({ address: utils.getAddress(address) }));
    }
  }
});

export const networkChanged = createAsyncThunk<
  { availability: ServiceAvailability; network: Network },
  Network
>('config/networkChanged', async (network, { getState, dispatch, rejectWithValue }) => {
  const availability = getServiceAvailability(network);

  return { availability, network };
});

import { OpenContextModal } from "@mantine/modals/lib/context";

export * from "./AccountModal";

export const accountModalContext: OpenContextModal = {
  size: "100vw",
  shadow: "xl",
  withCloseButton: false,
  styles: {
    inner: { padding: 0 },
  },
  innerProps: {},
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultMantineColor } from "@mantine/styles/lib/theme/types/MantineColor";
import { init, networkChanged } from "./configThunks";
import { ServiceAvailability } from "./configConstants";

export interface ConfigState {
  isInitialized: boolean;
  color: DefaultMantineColor;
  availability: ServiceAvailability;
  network?: {
    chainId: number;
    ensAddress?: string;
    name: string;
  };
}

const initialState: ConfigState = {
  color: "pink",
  isInitialized: false,
  availability: ServiceAvailability.ProviderNotFound,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setColor: (state, action: PayloadAction<DefaultMantineColor>) => {
      state.color = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(networkChanged.fulfilled, (state, { payload }) => {
      state.availability = payload.availability;
      state.network = payload.network;
    });
    builder.addCase(init.fulfilled, (state) => {
      state.isInitialized = true;
    });
  },
});

export const { setColor } = configSlice.actions;
export const configReducer = configSlice.reducer;

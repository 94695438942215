import { api } from '../../api';
import {
  BecomeFilmmakerForm,
  CollectorDto,
  CreatorPreviewDto,
  FilmmakerRequestDto,
  ProcessFilmmakerRequestDto,
  ProfileDto,
  ProfileFormDto,
  UpdateProfileForm,
} from './userTypes';
import { PublicFile } from '../../types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findUser: builder.query<ProfileDto, { id: string }>({
      query: ({ id }) => `users/${id}`,
    }),
    checkUsername: builder.mutation<boolean, { username: string }>({
      query: (body) => ({ url: 'users/username', method: 'POST', body }),
    }),
    becomeFilmmaker: builder.mutation<void, BecomeFilmmakerForm>({
      query: (form) => ({
        url: 'users/become-filmmaker',
        method: 'POST',
        body: form,
      }),
    }),
    processFilmmakerRequest: builder.mutation<void, ProcessFilmmakerRequestDto>({
      query: (dto) => ({
        url: 'users/process-filmmaker-request',
        method: 'POST',
        body: dto,
      }),
    }),
    findRequests: builder.query<FilmmakerRequestDto[], string>({
      query: (searchValue) => ({
        url: `users/filmmaker-requests`,
        params: {
          searchValue,
        },
      }),
    }),
    findCollectors: builder.query<CollectorDto[], void>({
      query: () => ({
        url: `users/collectors`,
      }),
    }),
    findCreators: builder.query<CreatorPreviewDto[], void>({
      query: () => ({
        url: `users/creators`,
      }),
    }),
    findProfile: builder.query<ProfileDto, void>({
      query: () => `users/profile`,
    }),
    findProfileForm: builder.query<ProfileFormDto, void>({
      query: () => `users/profile/form`,
      keepUnusedDataFor: 0,
    }),
    updateProfile: builder.mutation<ProfileDto, UpdateProfileForm>({
      query: (form) => ({
        url: 'users/profile',
        method: 'POST',
        body: form,
      }),
    }),
    confirmEmail: builder.mutation<void, void>({
      query: () => ({
        url: 'users/confirm-email',
        method: 'POST',
      }),
    }),
    updateAvatar: builder.mutation<PublicFile, FormData>({
      query: (form) => ({
        url: 'users/avatar',
        method: 'POST',
        body: form,
      }),
    }),
  }),
});

export const {
  useFindUserQuery,
  useFindCollectorsQuery,
  useFindCreatorsQuery,
  useFindRequestsQuery,
  useFindProfileQuery,
  useFindProfileFormQuery,
  useBecomeFilmmakerMutation,
  useUpdateAvatarMutation,
  useUpdateProfileMutation,
  useProcessFilmmakerRequestMutation,
  useConfirmEmailMutation,
} = userApi;

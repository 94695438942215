import { OpenContextModal } from "@mantine/modals/lib/context";

export * from "./DropdownModal";

export const dropdownModalContext: OpenContextModal = {
  size: "100vw",
  styles: {
    inner: { padding: 0 },
  },
  innerProps: {},
};

import { getIdenticonSource } from '../../utils';
import classNames from 'classnames';
import styles from './AppAvatar.module.scss';
import { IconUser } from '../../icons';

type Props = {
  avatar?: string | undefined | null;
  address: string;
  className?: string;
  onClick?: () => void;
};

export function AppAvatar({ address, avatar, className, onClick }: Props) {
  const src = avatar || (address && getIdenticonSource(address));

  return (
    <div onClick={onClick} className={classNames(styles.appAvatar, className)}>
      {src ? <img src={src} alt='avatar' /> : <IconUser />}
    </div>
  );
}

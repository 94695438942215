import { Environment } from '../types';

export const GB1 = 1000000000;
export const GB5 = 5000000000;
export const GB2 = 2000000000;
export const MB5 = 5000000;
export const MB10 = 10000000;
export const MB50 = 50000000;
export const MB100 = 100000000;

export const PATH_REGEX = /[a-z][^\\:|<>"*?]*$/;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT! as Environment;

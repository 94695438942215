import { api } from '../../api';
import {
  BonusContentDto,
  BonusDto,
  Collection,
  CollectionForm,
  CollectionPreview,
  CollectionType,
  CreateCollectionForm,
  CreateFundingCollectionForm,
  DropConfigForm,
  FilmShelfPreviewDto,
  FundingCollectionDto,
  FundingCollectionForm,
  Task,
  UpdateOptionDto,
} from './collectionTypes';
import { Stream } from '../../types';

export const collectionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findOne: builder.query<Collection, { symbol: string }>({
      query: ({ symbol }) => ({ url: `collection/${symbol}` }),
    }),
    findFundingCollection: builder.query<FundingCollectionDto, { symbol: string }>({
      query: ({ symbol }) => ({ url: `funding-collection/${symbol}` }),
    }),
    findAll: builder.query<CollectionPreview[], void>({
      query: () => ({ url: `collection` }),
    }),
    findFundingCollections: builder.query<CollectionPreview[], void>({
      query: () => ({ url: `funding-collection` }),
    }),
    findUserFanCollections: builder.query<CollectionPreview[], { userId: string }>({
      query: ({ userId }) => ({ url: `collection/user/${userId}` }),
    }),
    findUserFundingCollections: builder.query<CollectionPreview[], { userId: string }>({
      query: ({ userId }) => ({ url: `funding-collection/user/${userId}` }),
    }),
    findUserShelf: builder.query<FilmShelfPreviewDto[], { userId: string }>({
      query: ({ userId }) => ({ url: `collection/user-shelf/${userId}` }),
    }),
    findFeatured: builder.query<CollectionPreview[], void>({
      query: () => ({ url: `collection/featured` }),
    }),
    findCollectionForm: builder.query<CollectionForm, { symbol: string }>({
      query: ({ symbol }) => ({ url: `collection/form/${symbol}` }),
    }),
    findFundingCollectionForm: builder.query<FundingCollectionForm, { symbol: string }>({
      query: ({ symbol }) => ({ url: `funding-collection/form/${symbol}` }),
    }),
    publish: builder.mutation<void, { symbol: string }>({
      query: ({ symbol }) => ({ url: `collection/publish/${symbol}`, method: 'POST' }),
    }),
    publishFundingCollection: builder.mutation<void, { symbol: string }>({
      query: ({ symbol }) => ({ url: `funding-collection/publish/${symbol}`, method: 'POST' }),
    }),
    addToWaitlist: builder.mutation<void, { email: string; symbol: string }>({
      query: ({ symbol, email }) => ({
        url: `funding-collection/add-to-waitlist/${symbol}`,
        method: 'POST',
        body: { email },
      }),
    }),
    create: builder.mutation<void, CreateCollectionForm>({
      query: (form) => ({ url: 'collection', method: 'POST', body: form }),
    }),
    createFundingCollection: builder.mutation<void, CreateFundingCollectionForm>({
      query: (form) => ({ url: 'funding-collection', method: 'POST', body: form }),
    }),
    updateFundingCollectionStory: builder.mutation<void, { symbol: string; story: string }>({
      query: (form) => ({ url: 'funding-collection/story', method: 'POST', body: form }),
    }),
    updateFundingCollectionGoal: builder.mutation<
      void,
      { symbol: string; goal: number; percentage: number }
    >({
      query: (form) => ({ url: 'funding-collection/goal', method: 'POST', body: form }),
    }),
    updateFundingCollectionRoadmap: builder.mutation<void, { symbol: string; tasks: Task[] }>({
      query: (form) => ({ url: 'funding-collection/roadmap', method: 'POST', body: form }),
    }),
    updateFundingCollectionDropConfig: builder.mutation<void, DropConfigForm & { symbol: string }>({
      query: (dto) => ({ url: 'funding-collection/drop-config', method: 'POST', body: dto }),
    }),
    startPublicSale: builder.mutation<void, { symbol: string }>({
      query: ({ symbol }) => ({
        url: `funding-collection/start-public-sale/${symbol}`,
        method: 'POST',
      }),
    }),
    goNextStage: builder.mutation<void, { symbol: string }>({
      query: ({ symbol }) => ({
        url: `funding-collection/go-next-stage/${symbol}`,
        method: 'POST',
      }),
    }),
    updateFundingCollectionOptions: builder.mutation<
      void,
      { symbol: string; options: UpdateOptionDto[] }
    >({
      query: (form) => ({ url: 'funding-collection/options', method: 'POST', body: form }),
    }),
    updateFundingCollectionMedia: builder.mutation<
      void,
      {
        symbol: string;
        videoIds: string[];
        imageIds: string[];
        pitchVideoId: string;
        coverImageId: string | null;
      }
    >({
      query: (form) => ({ url: 'funding-collection/media', method: 'POST', body: form }),
    }),
    updateFundingCollectionPitchdeck: builder.mutation<
      void,
      {
        symbol: string;
        pitchdeckId: string;
        scriptId: string;
      }
    >({
      query: (form) => ({ url: 'funding-collection/pitchdeck', method: 'POST', body: form }),
    }),
    updateImdbId: builder.mutation<void, { imdbId: string; symbol: string }>({
      query: (form) => ({ url: 'collection/imdb', method: 'POST', body: form }),
    }),
    uploadVideos: builder.mutation<void, { symbol: string; movieId: string; trailerIds: string[] }>(
      {
        query: (form) => ({ url: 'collection/videos', method: 'POST', body: form }),
      },
    ),
    updateTrailers: builder.mutation<void, { symbol: string; trailerIds: string[] }>({
      query: (form) => ({ url: 'collection/trailers', method: 'POST', body: form }),
    }),
    addDescription: builder.mutation<void, { symbol: string; description: string }>({
      query: (form) => ({ url: 'collection/description', method: 'POST', body: form }),
    }),
    uploadBonuses: builder.mutation<void, { symbol: string; bonuses: BonusDto[] }>({
      query: (form) => ({ url: 'collection/bonuses', method: 'POST', body: form }),
    }),
    checkSymbol: builder.mutation<boolean, { symbol: string }>({
      query: (body) => ({ url: 'collection/symbol', method: 'POST', body }),
    }),
    findStreams: builder.query<Stream[], { videoId: string }>({
      query: ({ videoId }) => ({ url: `collection/stream/${videoId}` }),
    }),
    findBonusContent: builder.query<
      BonusContentDto[],
      { id: string; collectionType: CollectionType }
    >({
      query: ({ id, collectionType }) => ({
        url: `${
          collectionType === CollectionType.Fan ? 'collection' : 'funding-collection'
        }/content/${id}`,
      }),
    }),
  }),
});

export const {
  useStartPublicSaleMutation,
  useGoNextStageMutation,
  useAddToWaitlistMutation,
  useFindAllQuery,
  useFindFundingCollectionsQuery,
  useFindFundingCollectionQuery,
  useFindUserFanCollectionsQuery,
  useFindUserShelfQuery,
  useFindUserFundingCollectionsQuery,
  useFindFeaturedQuery,
  useFindOneQuery,
  useLazyFindStreamsQuery,
  useFindBonusContentQuery,
  useFindCollectionFormQuery,
  useCreateMutation,
  useCreateFundingCollectionMutation,
  useUpdateImdbIdMutation,
  useUploadVideosMutation,
  useUpdateTrailersMutation,
  useUploadBonusesMutation,
  useAddDescriptionMutation,
  usePublishMutation,
  useFindFundingCollectionFormQuery,
  useUpdateFundingCollectionStoryMutation,
  useUpdateFundingCollectionGoalMutation,
  useUpdateFundingCollectionRoadmapMutation,
  useUpdateFundingCollectionDropConfigMutation,
  useUpdateFundingCollectionMediaMutation,
  useUpdateFundingCollectionPitchdeckMutation,
  useUpdateFundingCollectionOptionsMutation,
  usePublishFundingCollectionMutation,
} = collectionApi;

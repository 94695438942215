import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber, ethers } from 'ethers';
import { UpdateOptionDto } from '../modules/collection';
import { ethToWei } from '../utils';

export const parseTransaction = (transaction: TransactionResponse, abi: string[]) => {
  const iface = new ethers.utils.Interface(abi);
  const description = iface.parseTransaction(transaction);

  return { ...transaction, description };
};

export function mapOptions(options: UpdateOptionDto[]) {
  return options.reduce<{
    optionPrices: BigNumber[];
    optionSupply: number[];
  }>(
    (acc, { priceWeb3, maxNft }) => {
      const { optionPrices, optionSupply } = acc;
      optionPrices.push(ethToWei(priceWeb3));
      optionSupply.push(maxNft);

      return acc;
    },
    { optionPrices: [], optionSupply: [] },
  );
}

import { RootState } from "../../store";
import { hasAccess } from "../../utils";
import { Role } from "./authTypes";

export const selectAccount = (state: RootState) => state.auth.account;
export const selectUser = (state: RootState) => state.auth.user;

export const selectIsCreator = (state: RootState) => {
  const { user } = state.auth;

  return !!user && hasAccess(user, Role.Creator);
};

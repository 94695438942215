import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAccount, IUser, TokensDto, WalletType } from './authTypes';
import { ethereum, provider } from '../../ethers';
import { utils } from 'ethers';
import { authApi } from './authApi';
import { areAddressesSame, getUserFromToken, removeTokens, setTokens } from '../../utils';
import { setAccount, setUser } from './authSlice';
import { setProfile } from '../user';
import { RootState } from '../../store';

export const connect = createAsyncThunk<IAccount, void>(
  'auth/connect',
  async (arg, { dispatch, rejectWithValue, getState }) => {
    if (!provider) {
      return rejectWithValue('Provider not found');
    }

    try {
      const [account] = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      await dispatch(authenticate());

      return dispatch(getAccount(account)).unwrap();
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const authenticate = createAsyncThunk<IUser | null, void>(
  'auth/authenticate',
  async (account, { dispatch, rejectWithValue, getState }) => {
    await dispatch(clearUserData());
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue('Signer not found');
    }

    const address = await signer.getAddress();
    const { nonce } = await dispatch(
      authApi.endpoints.findNonce.initiate({ address }, { forceRefetch: true }),
    ).unwrap();
    const signature = await signer.signMessage(`I am signing my one-time nonce: ${nonce}`);
    const tokens = await dispatch(
      authApi.endpoints.signIn.initiate({ address, signature }),
    ).unwrap();

    setTokens(tokens);
    return getUserFromToken();
  },
);

export const onGoogleSignUp = createAsyncThunk<IUser | null, TokensDto>(
  'auth/onGoogleSignUp',
  async (tokens, { dispatch, rejectWithValue, getState }) => {
    await dispatch(clearUserData());

    setTokens(tokens);
    return getUserFromToken();
  },
);

export const onAccountChange = createAsyncThunk<
  IAccount | null,
  { accounts: string[]; shouldReauth?: boolean },
  { state: RootState }
>('auth/onAccountChange', async ({ accounts, shouldReauth = true }, { getState, dispatch }) => {
  const [address] = accounts;
  const user = getState().auth.user;

  if (!user || user.type === WalletType.INTERNAL) return null;

  if (areAddressesSame(address, user.address)) {
    return dispatch(getAccount(address)).unwrap();
  } else {
    await dispatch(clearUserData());
    return null;
  }
});

export const checkAuthentication = createAsyncThunk<
  IUser | null,
  { shouldReauth: boolean } | undefined
>('auth/checkAuthentication', async (arg = { shouldReauth: true }, { dispatch }) => {
  if (!provider) {
    return null;
  }

  const signer = provider.getSigner();
  const address = await signer.getAddress();

  const user = getUserFromToken();

  if (user || !arg.shouldReauth) {
    return user;
  }

  await dispatch(clearUserData());

  const { nonce } = await dispatch(
    authApi.endpoints.findNonce.initiate({ address }, { forceRefetch: true }),
  ).unwrap();

  const signature = await signer.signMessage(`I am signing my one-time nonce: ${nonce}`);

  const tokens = await dispatch(authApi.endpoints.signIn.initiate({ address, signature })).unwrap();

  setTokens(tokens);

  return getUserFromToken();
});

export const signOut = createAsyncThunk<void, void>('auth/signOut', async (arg, { dispatch }) => {
  await dispatch(authApi.endpoints.signOut.initiate()).unwrap();
  await dispatch(clearUserData());
});

export const clearUserData = createAsyncThunk<void, void>(
  'auth/clearUserData',
  async (arg, { dispatch }) => {
    removeTokens();
    dispatch(setUser(null));
    dispatch(setProfile(null));
    dispatch(setAccount(null));
  },
);

export const getAccount = createAsyncThunk<IAccount, string>('auth/getAccount', async (address) => {
  return { address: utils.getAddress(address) };
});

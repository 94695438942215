import { S3VideoStreamQuality } from '../enums';
import { FC, ReactNode } from 'react';

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export interface PaginatedList<TData> {
  results: TData[];
  total: number;
}

export interface RejectPayload<Error> {
  rejectValue: Error;
}

export type PublicFile = {
  url: string;
  key: string;
};

export type VideoDto = {
  id: string;
  thumbnail?: string;
  isBeingConverted: boolean;
  streams: Stream[];
};

export interface Stream {
  quality: S3VideoStreamQuality;
  url: string;
  thumbnail: string;
}

export interface AppTab {
  tabKey: string;
  tabLabel: string;
  tabContent: ReactNode | FC;
  isDisabled?: boolean;
  isDone?: boolean;
  tabClassName?: string;
}

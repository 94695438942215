import { Web3Provider, EtherscanProvider } from "@ethersproject/providers";

const ethers = require("ethers");
const key = process.env.REACT_APP_API_KEY;
const url = "wss://eth-rinkeby.alchemyapi.io";

// const init = function () {
//   const customWsProvider = new ethers.providers.WebSocketProvider(url);
//
//   customWsProvider.on("pending", (tx: any) => {
//     customWsProvider.getTransaction(tx).then((transaction: any) => {
//       console.log(transaction);
//     });
//   });
//
//   customWsProvider._websocket.on("error", async () => {
//     console.log(`Unable to connect retrying in 3s...`);
//     setTimeout(init, 3000);
//   });
//   customWsProvider._websocket.on("close", async (code: any) => {
//     console.log(
//       `Connection lost with code ${code}! Attempting reconnect in 3s...`
//     );
//     customWsProvider._websocket.terminate();
//     setTimeout(init, 3000);
//   });
// };

// export const etherscanProvider = new EtherscanProvider(
//   4,
//   process.env.REACT_APP_API_KEY || undefined
// );

export const ethereum = window.ethereum;

export const provider = ethereum
  ? new Web3Provider(ethereum, "any")
  : undefined;

import { Environment } from '../types';
import { utils } from 'ethers';
import { ENVIRONMENT } from '../constants';

export const FACTORY_ADDRESS = process.env.REACT_APP_FACTORY_CONTRACT!;
export const PROVIDER_ADDRESS = process.env.REACT_APP_PROVIDER_CONTRACT!;

export const SEPOLIA = {
  name: 'Sepolia',
  id: 11155111,
  currency: 'ETH',
  scanUrl: 'https://sepolia.etherscan.io',
};

export const MUMBAI = {
  name: 'Mumbai',
  id: 80001,
  currency: 'MATIC',
  scanUrl: 'https://mumbai.polygonscan.com',
};

export const POLYGON = {
  name: 'Polygon',
  id: 137,
  currency: 'MATIC',
  scanUrl: 'https://polygonscan.com',
};

const networks: Record<
  Environment,
  { name: string; id: number; currency: string; scanUrl: string }
> = {
  [Environment.Development]: SEPOLIA,
  [Environment.Staging]: MUMBAI,
  [Environment.Production]: POLYGON,
};

export const SUPPORTED_NETWORK: { name: string; id: number; currency: string; scanUrl: string } =
  networks[ENVIRONMENT];

export const INSUFFICIENT_FUNDS_ERROR_CODE = -32603;
export const UNKNOWN_NETWORK = 4902;
export const REJECTED_ERROR_CODE = 'ACTION_REJECTED';

export const networkMap = {
  POLYGON_MAINNET: {
    chainId: utils.hexValue(137), // '0x89'
    chainName: 'Matic(Polygon) Mainnet',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://www.polygonscan.com/'],
  },
  MUMBAI_TESTNET: {
    chainId: utils.hexValue(80001), // '0x13881'
    chainName: 'Matic(Polygon) Mumbai Testnet',
    nativeCurrency: { name: 'tMATIC', symbol: 'tMATIC', decimals: 18 },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  },
};
